$white: #ffffff;
$black: #111111;
$orange: rgba(238,125,62,255);
$orange-light: rgba(197, 102, 34, 0.1);
$gray-bg: #eeeeee;
$gray: #f2f2f2;
$brown: #783c11;
$green: #23c220;
$red: #ff3123;
$warning: #ff7a00;


$content-size: 1399px;