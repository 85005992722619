.editpage__card {
  display: flex;
}

.edit__left__card {
  width: 459px;
}

.edit__card__text {
  margin: 15px 0;
}

.text__link {
  text-decoration: none;
  color: rgba(238,125,62,255);
}

.card__header {
  width: 100%;
  max-width: 459px;
  height: 116px;
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.avatar__img {
  margin-left: 10px;
}

.image__card {
  margin-left: 16px;
  line-height: 30px;
}

.edit__card__title {
  line-height: 33px;
}

.change__btn {
  padding: 5px 10px;
  border: 1px solid rgba(238,125,62,255);
  border-radius: 1000px;
  background-color: #fff;

  color: rgba(238,125,62,255);
}

/* Input */
.editpage__input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form__input {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
}

.edit-page input:not(.checkbox__input) {
  padding: 0px 10px;
  width: 204px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
}

.edit-page span {
  margin-left: 3px;
}

.edit-page label {
  display: flex;
  flex-direction: column;
}

.text__area {
  margin-top: 20px;
  margin-bottom: 10px;
}

.textarea {
  width: 449px;
  height: 116px;
  resize: none;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
}

.form__input {
  margin-top: 20px;
  line-height: 30px;
}

/* Second card */

.second-card {
  margin-top: 50px;
}

.second__card__text {
  margin-top: 35px;
  margin-bottom: 10px;
}

.select {
  width: 459px;
  height: 44px;

  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
}

.select__option {
  width: 459px;
  margin-left: 10px;
  background-color: #ffffff;
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

/* Map box */

.map--box {
  margin-top: 143px;
  width: 460px;
  height: 400px;
  background: #ffffff;
  border-radius: 3px;
}

.map__address {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}

.map__input {
  margin-left: 10px;
  margin-top: 20px;
  width: 298px;
  height: 44px;
  background: #ffffff;
  border: 1px solid gray;
  border-radius: 1000px;
}

.map__btn {
  width: 116px;
  height: 44px;
  margin-left: 5px;
  color: #fff;
  background: #0b0b0b;
  border: 1px solid #0b0b0b;
  border-radius: 1000px;
}

.map__google {
  margin-left: 8px;
  margin-top: 30px;
}

.checkbox {
  margin-top: 42px;
  display: flex;
}

.checkbox__input {
  width: 20px;
  height: 20px;
  background-color: rgba(238,125,62,255);
}

.checkbox__text {
  width: 297px;
  height: 34px;
  margin-left: 10px;
}

.register__btn {
  margin-top: 61px;
  background: rgba(238,125,62,255);
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  border: none;
  font-size: 13px;
  line-height: 16px;
  padding: 14px 48px;
  text-align: center;
}

/* Right Side */

.edit__card {
  width: 342px;
  height: 500px;
  /* margin-left: 130px; */

  padding-top: 30px;
  border-radius: 5px;
  background-color: #fff;
}

.edit__card__content {
  width: 310px;
  margin: 0 auto;
}

.blockquote__card {
  width: 252px;
  height: 62px;
  margin: 15px 0px;
  padding: 10px 15px;
  margin-left: 13px;
  background: #e7d3c5;
  border: 1px solid #e7d3c5;
  border-radius: 4px;
}

.blockquote__text {
  color: rgba(238,125,62,255);
}

.edit-page textarea {
  resize: none;
}
.price__select {
  margin: 10px 0;
}
.price-name {
  margin-top: 20px;
}

.phone-number {
  width: 435px;
}
