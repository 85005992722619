@import "../../styles/modules.scss";

.download-app {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: _rem(190px) 0 _rem(69px) 28rem;
	background: url('../../assets/img/download-bg.png') left bottom -204px no-repeat;
	@include tablet {
		padding: 190px 0 28rem;
		background-position: left 70px bottom -204px;
	}
	@include phones {
		padding: 120px 0 28rem;
		background-position: left 110% bottom -204px;
	}
	&-s {
		.white-icon {
			svg {
				fill: none;
			}
		}
	}
	& > * {&:not(:last-child) {
		margin-right: _rem(113px);
	}}
	.app {
		margin-bottom: _rem(40px);
		&-btns {
			display: flex;
			margin-bottom: _rem(21px);
			& > * {&:not(:last-child) {
				margin-right: _rem(5px);
			}}
		}
		h3 {
			margin-bottom: _rem(8px);
		}
		p {
			line-height: _rem(19px);
			max-width: _rem(221px);
			width: 100%;
		}
	}
	.doc {
		padding-right: 10%;
		button {
			margin-bottom: _rem(21px);
			span {
				color: $black;
			}
		}
		h3 {
			margin-bottom: _rem(8px);
		}
		p {
			line-height: _rem(19px);
			max-width: _rem(400px);
			width: 100%;
		}
	}
}