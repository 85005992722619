@import "../../styles/modules.scss";

.categories {
  &-s {
    padding-top: calc(50px/16px) + 0rem;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: calc(10px/16px) + 0rem;;
    margin-bottom: 2rem;

    @include tablet {
      flex-wrap: wrap;
      grid-template-columns: repeat(2, 1fr);
    }
    @include phones {
      grid-template-columns: 1fr;
    }
    li {
      flex-basis: 49.65%;
      border-radius: calc(5px/16px) + 0rem;
      box-shadow: 0px 9px 24px 0px #75380D3B;
      background: linear-gradient(180deg, #E99C65 0%, #FFE600 41.15%, #C08E54 100%);
      padding: 2px;

      span {
        display: list-item;
        padding: calc(24px/16px) + 0rem calc(22px/16px) + 0rem;
        background: $white;
        border-radius: calc(5px/16px) + 0rem;
        width: 100%;
        height: 100%;
      }

      a {
        display: flex;
        justify-content: space-between;
        .info {
          h2 {
            font-family: SFProDisplay_600;
            font-size: calc(17px/16px) + 0rem;
            line-height: calc(22px/16px) + 0rem;
            font-weight: 700;
            margin-bottom: calc(10px/16px) + 0rem;
            color: $black;
          }
          p {
            font-family: timesNewRoman_700;
            color: $orange;
            font-size: calc(14px/16px) + 0rem;
            line-height: calc(16px/16px) + 0rem;
            opacity: 0.7;
            font-style: italic;
          }
        }
        .img {
          //width: rem(90px);
          width: 88px;
          height: 85px;
          img {
            height: 100%;
          }
        }
      }
    }
  }
}
