@import "../../styles/modules.scss";

.modal {
	width: 100%;
  	display: flex;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba($color: #000000, $alpha: 0.4);
	justify-content: center;
	align-items: center;
	z-index: 1000;
	
	&-body {
		position: relative;
		background: $white;
		padding: _rem(50px) _rem(55px) _rem(56px) _rem(60px);
		h4 {
			font-family: SFProDisplay_700;
			font-size: _rem(22px);
			line-height: _rem(27px);
			color: #000000;
			margin-bottom: _rem(5px);
		}
		p {
			font-weight: SFProDisplay_400;
			font-size: _rem(13px);
			line-height: _rem(20px);
		}
	}
}
.alert-form {
	background: rgba($color: $orange, $alpha: .1);
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: _rem(10px) _rem(16px) _rem(10px);
	img {
		margin-right: _rem(6px);
	}
	p {
		font-family: TimesNewRoman_400;
		font-size: _rem(13px);
		line-height: _rem(17px);
		color: $orange;
		font-style: italic;
	}
	margin: _rem(23px) 0 _rem(30px);
}
.login-modal {
	.modal-body {
		max-width: _rem(576px);
		width: 100%;
	}
	form {
		max-width: _rem(367px);
		width: 100%;
		.form-input {
			margin-bottom: 0;
		}
		label {
			font-family: SFProDisplay_400;
		}
		button {
			margin-top: _rem(15px);
			margin-bottom: _rem(35px);
		}
		p {
			color: #000;
			a {
				font-size: _rem(13px);
				line-height: _rem(16px);
				text-decoration-line: underline;
				color: #1B74FF;
				&:first-child {
					margin-right: _rem(10px);
				}
				&:last-of-type {
					margin-left: _rem(10px);
					color: $orange
				}
			}
		}
	}
}

.close-btn {
	position: absolute;
	right: _rem(10px);
	top: _rem(10px);
	display: flex;
	transition: all ease .2s;
	svg {
		width: _rem(24px);
		height: _rem(24px);
	}
	&:hover {
		transform: scale(1.1);
	}
}

.confirm-modal {
	form {
		margin-top: _rem(30px);
		label {
			color: #0F1826;
		}
	}
}

.mt-4 {
	margin-top: 10px;
}