@import "../../styles/modules.scss";

.footer {
	background: $black;
	padding: __rem(30px) 0 _rem(20px);

	&__top {
		border-bottom: 1px solid rgba(225,225,225, .1);
		padding-bottom: _rem(27px);
		padding-top: _rem(27px);
		&-body {
			display: flex;
			align-items: center;
			@include laptop {
				align-items: flex-start;
			}
			@include phones {
				flex-wrap: wrap;
				& > a {
					flex-basis: 100%;
				}
			}
			ul {
				display: flex;
				@include laptop {
					flex-wrap: wrap;
					& > * {&:not(:last-child) {
						margin-bottom: 10px;
					}}
				}
				@include phones {
					flex-basis: 50%;
					margin-top: 50px;
				}
				& > * {&:not(:last-child) {
					margin-right: _rem(30px);
				}}
				li {
					@include laptop {
						flex-basis: 100%;
					}
					a {
						color: $white;
						font-weight: 400;
						transition: all ease .2s;
						&:hover {
							color: $orange;
						}
					}
				}
				&:first-of-type {
					padding-left: _rem(150px);
					@include phones {
						padding-left: 0;
					}
				}
				&:last-of-type	{
					margin-left: auto;
					&>* {
							&:not(:last-child) {
								margin-right: _rem(22px);
							}
						}
				}
			}
		}
	}
	&__center {
		padding: _rem(12px) 0;
		border-bottom: 1px solid rgba(225, 225, 225, .1);
		&-body {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include phones {
				flex-wrap: wrap;
				& > * {&:not(:last-child) {
					margin-bottom: 20px;
				}}
			}
		}
		p {
			flex-shrink: 1;
			font-size: _rem(10px);
			line-height: _rem(16px);
			color: #FFFFFF;
			opacity: 0.4;
			width: _rem(446px);
			padding-right: _rem(10px);
		}
		ul {
			display: flex;
			
			& > * {&:not(:last-child) {
				margin-right: 5px;
			}}
			li {
				background: #FFFFFF;
				border-radius: 4px;
				height: _rem(34px);
				width: _rem(70px);
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					max-width: 77%;
					max-height: 70%;
				}
			}
		}
	}
	&__bottom {
		padding-top: _rem(13px);
		padding-bottom: _rem(13px);
		&-body {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include tablet {
				flex-wrap: wrap;
				& > * {&:not(:last-child) {
					margin-bottom: 20px;
				}}
			}
			ul:first-of-type {
				display: flex;
				@include phones {
					flex-basis: 100%;
					flex-wrap: wrap;
					& > * {&:not(:last-child) {
						margin-bottom: 10px;
					}}
					li {
						flex-basis: 100%;
						text-align: center;
					}
				}
				li {
					p {
						font-weight: 400;
						font-size: _rem(10px);
						line-height: _rem(19px);
						color: #FFFFFF;
						opacity: 0.4;
						transition: all ease .2s;
						margin-right: _rem(28px);
						@include phones {
							margin-right: 0;
						}
					}
					a {
						margin-right: _rem(17px);
						@include phones {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}
ul.social-links {
	display: flex;
	@include phones {
		flex-basis: 100%;
		justify-content: center;
	}
	& > * {&:not(:last-child) {
		margin-right: 24px;
	}}
	li {
		a {
			display: flex;
			transition: all ease .2s;
			svg {
				fill: $white;
			}
			&:hover {
				transform: scale(1.2);
			}
		}
	}
}
.line-link {
	font-weight: 400;
	font-size: _rem(10px);
	line-height: _rem(19px);
	text-decoration-line: underline;
	color: #FFFFFF;
	opacity: 0.4;
	transition: all ease .2s;
	&:hover {
		opacity: 1;
	}
}