@import "../../styles/modules.scss";

.create-product {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include laptop {
    justify-content: center;
  }
  .btns {
    display: flex;
    margin-top: _rem(40px);
    & > * {
      &:not(:last-child) {
        margin-right: _rem(10px);
      }
    }
    button {
      border-radius: 3px;
      width: 50%;
      @include phones {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    @include phones {
      display: block;
    }
  }
  &-s {
    padding: _rem(52px) 0 _rem(236px);
    h2 {
      font-family: SFProDisplay_800;
      font-size: _rem(28px);
      line-height: _rem(33px);
      color: #0b0b0b;
      margin-bottom: _rem(12px);
    }
    .subtitle {
      font-weight: SFProDisplay_400;
      font-size: _rem(13px);
      line-height: _rem(18px);
      color: #0b0b0b;
      margin-bottom: _rem(37px);
      a {
        font-weight: 700;
        color: $orange;
      }
    }
    h5 {
      font-family: SFProDisplay_400;
      font-size: _rem(13px);
      line-height: _rem(16px);
      color: #0b0b0b;
      font-weight: 400;
      margin-bottom: _rem(15px);
    }
  }
  &__left {
    max-width: _rem(500px);
    width: 100%;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 9px 24px 0px rgba(117, 56, 13, 0.231372549);
    padding: 20px;
    @include laptop {
      order: 2;
      flex-basis: 100%;
    }
  }
  &__right {
    display: none;
    max-width: _rem(342px);
    width: 100%;
    margin-left: _rem(127px);
    @include laptop {
      order: 1;
      margin-left: 0;
      flex-basis: 100%;
      max-width: _rem(460px);
      margin: 0 50px 60px;
    }
    @include tablet {
      margin: 0 0 60px;
    }
  }

  &-btns {
    display: flex;
    background-color: $white;
    button {
      font-family: SFProDisplay_600;
      flex-basis: 50%;
    }
  }
}

.radio-btn-big {
  width: 50%;
  background: $white;
  label {
    padding: _rem(14px);
    cursor: pointer;
    border-radius: 3px;
    flex-basis: 50%;
    //background: $white;
    background: #f3f2f2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SFProDisplay_600;
    font-size: _rem(13px);
    line-height: _rem(16px);
    text-align: center;
    color: #0b0b0b;
    transition: all ease 0.2s;
  }

  input {
    display: none;

    &:checked + label {
      background: $orange;
      color: $white;
    }
  }
}

.radio-list {
  display: flex;
  flex-wrap: wrap;
  & > * {
    &:not(:last-child) {
      margin-right: _rem(5px);
    }
  }
  .radio-btn {
    margin-bottom: _rem(5px);
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: _rem(13px);
      line-height: _rem(22px);
      color: $black;
      //background: $white;
      background: #f3f2f2;
      padding: _rem(11px) _rem(20px);
      border-radius: 50px;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        background: $orange;
        color: $white;
      }
    }
    input {
      display: none;
      &:checked + label {
        background: $orange;
        color: $white;
      }
    }
  }
}

.map {
  background: $white;
  border-radius: 3px;
  overflow: hidden;
  padding: _rem(5px);
  &-info {
    padding: _rem(15px) _rem(15px) _rem(10px);
  }
  &-address {
    width: 100%;
    display: flex;
    input {
      padding: _rem(14px) _rem(20px);
      background: $white;
      border: 1px solid #0b0b0b20;
      border-radius: 1000px;
      flex-shrink: 1;
      flex-grow: 1;
      margin-right: _rem(5px);
    }
  }
  p {
    padding-left: _rem(40px);
    font-size: _rem(11px);
    line-height: _rem(13px);
    margin-bottom: _rem(15px);
  }
  #map {
    height: _rem(190px);
  }
}
.image-upload {
  background: $white;
  border-radius: 3px;
  padding: _rem(5px);
  .image-outer {
    border: 1px dashed #b6b6b6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: _rem(20px);
    position: relative;
    transition: all ease 0.2s;
    &.highlight {
      border-color: $orange;
      border-width: 2px;
    }
    h5 {
      font-family: SFProDisplay_400;
      font-size: _rem(11px);
      line-height: _rem(13px);
      color: #0b0b0b;
      margin-bottom: _rem(5px);
    }
    p {
      font-size: _rem(11px);
      line-height: _rem(13px);
      color: $black;
      opacity: 0.3;
    }
    input {
      display: none;
    }
    label {
      z-index: 1;
      padding: _rem(10px) _rem(20px);
      width: auto;
      border-radius: 50px;
      background: $orange;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-family: SFProDisplay_400;
      font-size: _rem(11px);
    }
  }
  .files-name {
    font-family: SFProDisplay_600;
    font-size: _rem(11px);
    line-height: _rem(13px);
    color: rgba(238,125,62,255);
    span {
      font-family: SFProDisplay_400;
      color: #121212;
      margin-left: _rem(9px);
    }
  }
  .cancel-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(#ff0000, 0.3);
    @include size(_rem(24px));
    svg {
      width: 11px;
      height: 11px;
    }
  }
  .image-progress {
    background: rgba($orange, 0.2);
    padding: _rem(13px) _rem(10px);
    border-radius: 3px;
    margin: _rem(15px) 0 _rem(7px);
    display: none;
    &__left {
      flex-grow: 1;
    }
    &__right {
      flex-shrink: 0;
      flex-grow: 0;
    }
    .progress-bar:after {
      background: $orange;
      -webkit-background-size: 40px 40px;
      background-size: 40px 40px;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .progress-bar {
      height: 2px;
      transition: width 1s linear;
    }
    @for $i from 0 through 100 {
      .progress progress[value="#{$i}"] {
        & + .progress-value:before {
          content: "#{$i}%";
        }

        & ~ .progress-bg .progress-bar {
          width: $i * 1%;
        }
      }
    }
    .progress {
      margin-top: _rem(5px);
      position: relative;
      overflow: hidden;
      max-width: _rem(387px);
      width: 100%;
    }

    .progress progress {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      left: -777px;
    }

    .progress-bar {
      overflow: hidden;
      background: #ac92ec;
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .progress-value {
      color: #333;
      display: block;
      line-height: 21px;
      text-align: center;
    }

    .progress-bg {
      background: rgba($orange, 0.4);
      position: relative;
      height: 2px;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  .image-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: _rem(5px);
    grid-row-gap: _rem(5px);
    li {
      height: _rem(80px);
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: _rem(5px);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.sizes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include phones {
    margin-bottom: 40px;
  }
  p {
    font-family: SFProDisplay_400;
    flex-basis: 100%;
    margin-top: _rem(26px);
    font-size: _rem(13px);
    line-height: _rem(18px);
    color: #0f1826;
  }
  &-inputs {
    flex-basis: 100%;
    margin-bottom: _rem(20px);
    width: 100%;
    //background: #ffffff;
    background: #f3f2f2;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    margin-top: _rem(10px);

    & > * {
      &:not(:first-child) {
        border-left: 1px solid #cccccc;
      }
    }
    input {
      flex-basis: 33.33%;
      width: 33.33%;
      padding: _rem(16px) _rem(14px);
      border: none;
      background: #f3f2f2;
    }
  }
  &-input {
    flex-shrink: 1;
    flex-grow: 1;
    max-width: _rem(140px);
    width: 100%;
    margin-right: _rem(20px);
    @include phones {
      margin-bottom: 20px;
      max-width: 49%;
      flex-basis: 49%;
      margin-right: 2%;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    label {
      font-family: SFProDisplay_400;
      flex-basis: 100%;
      font-size: _rem(13px);
      line-height: _rem(18px);
      color: #0f1826;
      margin-bottom: _rem(10px);
      display: block;
    }
    &:last-of-type {
      margin-right: 0;
    }
    input {
      width: 100%;
      background: #f3f2f2;
      border-radius: 4px;
      padding: _rem(16px) _rem(14px);
      border: none;
    }
  }
}
.ipoteka-list {
  display: flex;
  flex-wrap: wrap;
  & > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  li {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    flex-direction: column;
    h5 {
      flex-basis: 100%;
    }
  }
}

.radios {
  display: flex;
  & > * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
.checkbox {
  &-list {
    display: flex;
    flex-wrap: wrap;
    & > * {
      &:not(:last-child) {
        margin-right: _rem(5px);
      }
    }
  }
  &-btn {
    margin-bottom: _rem(5px);
    label {
      display: flex;
      border-radius: 50px;
      justify-content: center;
      align-items: center;
      background: $white;
      padding: _rem(11px) _rem(16px);
      cursor: pointer;
      transition: all ease 0.2s;
      svg {
        transition: all ease 0.2s;
        margin-right: _rem(5px);
      }
      &:hover {
        background: $orange;
        color: $white;
        svg {
          fill: $white;
        }
      }
    }
    input {
      display: none;
      &:checked + label {
        background: $orange;
        color: $white;
        svg {
          fill: $white;
        }
      }
    }
  }
}
.error-par {
  color: #ff3e3e;
}

.form {
  background: #ffffff;
  border-radius: 3px;
  padding: _rem(40px) _rem(62px) _rem(60px) _rem(40px);
  @include tablet {
    padding: _rem(30px);
  }
  h3 {
    font-family: SFProDisplay_700;
    font-size: _rem(16px);
    line-height: _rem(19px);
    color: #0b0b0b;
    margin-bottom: _rem(5px);
  }
  p {
    font-family: SFProDisplay_400;
    font-size: _rem(13px);
    line-height: _rem(18px);
    color: #0f1826;
    a {
      font-family: SFProDisplay_700;
      text-decoration-line: underline;
      color: #1b74ff;
    }
  }
  form {
    margin-top: _rem(16px);

    button {
      background: linear-gradient(180deg, #2db4f7 0%, #1a92cc 100%);
      border-radius: 4px;
      color: $white;
      font-family: SFProDisplay_700;
      font-size: _rem(13px);
      line-height: _rem(16px);
      padding: _rem(14px);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: _rem(10px);
      svg {
        fill: $white;
        margin-right: _rem(8px);
      }
    }
  }
}

#amenities-list {
  img {
    @include size(17px);
    margin-right: _rem(7px);
  }
}

.form-price {
  display: grid;
  grid-template-columns: 1fr _rem(120px);
  gap: 10px;
  margin-bottom: _rem(30px);
  input {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: _rem(14px);
    font-weight: 400;
    font-size: _rem(13px);
    line-height: _rem(16px);
    &::placeholder {
      color: #0f1826;
      opacity: 0.4;
    }
  }
  &-choose {
    position: relative;
  }
  button {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    cursor: pointer;
    padding: _rem(14px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #0f1826;
      opacity: 0.4;
    }
  }
}

.input-gray {
    background: #f3f2f2 !important;
}
