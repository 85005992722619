.cards {
	&-s {
		padding: _rem(31px) 0 _rem(53px);
	}

	&-head {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > ul {
			display: flex;
			& > * {&:not(:last-child) {
				margin-right: _rem(21px);
			}}
			li {
				&:first-child {
					a {
						svg {
							transform: scale(-1);
						}
					}
				}
				a {
					display: flex;
					transition: all ease .2s;
					svg {
						fill: $white;
					}
					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}
	}
	&-list {
		position: relative;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		margin-bottom: _rem(40px);
		margin-top: _rem(20px);
		gap: _rem(10px);
		@include tablet {
			grid-template-columns: repeat(2, 1fr);
		}
		@include phones {
			grid-template-columns: 1fr;
		}
	}
	&-item {
		position: relative;
		background: #FFFFFF;
		overflow: hidden;
		border-radius: _rem(3px);
		margin-bottom: _rem(10px);
		cursor: pointer;
		box-shadow: 0px 9px 24px 0px #75380D3B;
		&.top {
			&:hover {
				.cards-item-info__top {
					p {
						font-weight: 700;
						color: $white;
					}
				}
			}
			span.top-span {
				font-weight: 900;
				font-size: _rem(12px);
				line-height: _rem(15px);
				color: #FFFFFF;
				padding: _rem(6px) _rem(12px);
				background: $orange;
				border-radius: 50px;
				position: absolute;
				top: _rem(15px);
				left: _rem(15px);
				z-index: 10;
			}
			.cards-item__bottom {
				background: $orange;
				p,span {
					color: $white;
				}
			}
   		}
		&:hover {
			.cards-item__top {
				&::after {
					opacity: .4;
				}
				img {
					transform: scale(1.1);
				}
			} 
			.cards-item-info__top {
				p {
					font-weight: 700;
					//color: $orange;
					color: #ffffff;
				}

				span {
					color: #ffffff;
				}
			}
			background: rgba(238,125,62,255);
		}
		&__top {
			height: _rem(152px);
			overflow: hidden;
			position: relative;
			&::after {
				content: '';
				width: 100%;
				height: 100%;
				background-color: $black;
				opacity: 0;
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 1;
				transition: all ease .3s;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: all ease .3s;
			}
		}

		&:hover .cards-item-info__bottom p{
			color: #ffffff;
		}

		&-info {
			padding: _rem(13px) _rem(15px) _rem(16px);
			& > div {
				display: flex;
				justify-content: space-between;
			}
			&__top {
				p, span {
					font-weight: 400;
					font-size: _rem(12px);
					line-height: _rem(15px);
					color: $black;
					transition: all ease .2s;
				}
				margin-bottom: _rem(5px);
			}
			&__bottom {
			p, span {
					font-weight: 400;
					font-size: _rem(10px);
					line-height: _rem(12px);
					color: $brown
				}
			}
		}
	}
	button {
		margin: 0 auto;
	}
}

.save {
	svg {
		fill: $white;
	}
}