@import "../../styles/modules.scss";

.youtube-video {
  height: 15rem;
  width: 100%;
  object-fit: cover;
  margin: 0rem 0 2rem;
  padding-left: 1rem;

  &.phone-video {
    @media screen and (min-width: 1100px) {
      display: none;
    }
  }
  &.desktop-video {
    // display: none;

    @media screen and (max-width: 1099px) {
      display: none;
    }
  }
}

.slider {
  &-s {
    margin-bottom: _rem(40px);
  }
  &-body {
    display: flex;
    @include laptop {
      flex-wrap: wrap;
    }
  }
  &__left {
    flex-basis: pers(813px);
    margin-right: pers(10px);
    overflow: hidden;
    @include laptop {
      flex-basis: 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }
    .swiper {
      @include size(100%, _rem(440px));
      @include tablet {
        height: 100%;
      }
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
  }
  &__right {
    flex-basis: pers(574px);
    @include laptop {
      display: none;
      //flex-basis: 100%;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: _rem(10px);
      li {
        height: _rem(215px);
        @include tablet {
          height: 10rem;
        }
        @include phones {
          height: 8rem;
        }
        img {
          @include size(100%);
          object-fit: cover;
        }
      }
    }
  }
}
.product-page {
  .slider-s {
    margin-top: _rem(40px);
  }
}
.swiper {

  .swiper-button-prev,
  .swiper-button-next {
    left: auto;
    right: _rem(65px);
    bottom: _rem(18px);
    top: auto;
    @include size(_rem(30px));
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    &::after {
      display: none !important;
    }
    svg {
      height: _rem(12px);
      transform: scale(-1);
    }
  }
  .swiper-button-next {
    right: _rem(25px);
    svg {
      transform: scale(1);
    }
  }
  .swiper-scrollbar {
    width: 70%;
    left: auto;
    bottom: _rem(30px);
    right: _rem(135px);
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
    @include tablet {
      width: 50%;
    }
    .swiper-scrollbar-drag {
      background: #fff;
      height: 5px;
      border-radius: 0;
      margin-top: -2px;
    }
  }

  .swiper-pagination {
    color: transparent;
    bottom: _rem(15px);
    width: auto;
    left: _rem(25px);
    span {
      font-family: SFProDisplay_400;
      font-size: _rem(17px);
      line-height: _rem(30px);
      color: #ffffff;
      &:last-of-type {
        margin-left: _rem(6px);
        color: rgba($color: #fff, $alpha: 0.3);
        position: relative;
        &::after {
          content: "";
          left: -9px;
          top: 50%;
          transform: translateY(-50%) scale(-1);
          height: 100%;
          width: 2px;
          background-color: #fff;
          position: absolute;
        }
      }
    }
  }
}

.info-product {
  display: flex;
  margin-top: _rem(41px);

   @include laptop {
     display: block;
   }

  &-s {
    margin-top: _rem(40px);
    .product-info-title {
      font-family: SFProDisplay_500;
      font-size: _rem(17px);
      line-height: _rem(20px);
      color: $black;
      margin-bottom: _rem(7px);
    }
    .product-info-par {
      font-weight: 400;
      line-height: _rem(12px);
      color: #783c11;
    }
  }
  &-main {
    max-width: _rem(620px);
    box-shadow: 0px 9px 24px 0px rgba(117, 56, 13, 0.231372549);
    width: 100%;
    border-radius: 5px;
    background: #ffffff;
    padding: 20px;
  }

  &-sidebar {
    flex-basis: _rem(261px);
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: _rem(50px);
    @include laptop {
      margin-bottom: 50px;
      margin-right: 0;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-title {
    font-family: SFProDisplay_800;
    font-size: _rem(40px);
    line-height: _rem(46px);
    max-width: _rem(625px);
    width: 100%;
    margin-bottom: _rem(40px);
    @include tablet {
      display: none;
    }
  }
  .product-small-title {
    font-weight: SFProDisplay_700;
    font-size: _rem(15px);
    line-height: _rem(18px);
    color: $black;
    margin-bottom: _rem(20px);
  }
  .tags-list {
    margin-bottom: _rem(40px);
    display: flex;
    flex-wrap: wrap;
    & > * {
      &:not(:last-child) {
        margin-right: _rem(5px);
      }
    }
    li {
      span {
        font-family: TimesNewRoman_400;
        color: #0b0b0b;
        opacity: 0.6;
        font-style: _rem(11px);
        font-style: italic;
      }
      p {
        font-family: SFProDisplay_500;
        font-size: _rem(13px);
        line-height: _rem(16px);
      }
      margin-bottom: _rem(5px);
      padding: _rem(14px) _rem(30px);
      //background-color: $white;
      background: #f3f2f2;
      border-radius: 50px;
    }
  }

  .par {
    font-family: SFProDisplay_400;
    font-size: _rem(14px);
    line-height: _rem(23px);
    margin-bottom: _rem(50px);
  }
}
.comfort-tags {
  &-list {
    display: flex;
    flex-wrap: wrap;
    max-width: _rem(405px);
    width: 100%;

    & > * {
      &:not(:last-child) {
        margin-right: _rem(5px);
      }
    }
  }

  &-item {
    padding: _rem(11px) _rem(16px);
    border: 1px solid #000000;
    border-radius: 50px;
    margin-bottom: _rem(5px);

    svg {
      margin-right: _rem(5px);
    }
  }
}
.show-on-map {
  display: flex;
  width: 100%;
  margin: _rem(50px) 0 _rem(60px);
  &-address {
    background: #f3f2f2;
    border-radius: 3px;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    padding-left: _rem(12px);
    svg {
      margin-right: _rem(11px);
    }
  }
  button {
    margin-left: _rem(10px);
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.comments {
  position: relative;
  background: $white;
  padding: _rem(20px) _rem(25px) _rem(83px);
  margin-bottom: _rem(77px);
  &-profile {
    display: flex;
    margin-bottom: _rem(20px);
    &-avatar {
      width: _rem(56px);
      height: _rem(56px);
      border-radius: 50px;
      overflow: hidden;
      margin-right: _rem(13px);
      img {
        object-fit: cover;
      }
    }
  }
  &-message {
    background: $white;
    border: 1px solid rgba(11, 11, 11, 0.1);
    margin-bottom: _rem(28px);
    form {
      display: flex;
      padding: _rem(7px) _rem(15px);
      align-items: center;
    }
    button {
      background: transparent;
      border: none;
      svg {
        width: _rem(30px);
        height: _rem(30px);
      }
      flex-shrink: 0;
      flex-grow: 0;
      position: relative;
      padding: 0;
      &::after {
        content: "";
        left: _rem(-10px);
        top: 50%;
        transform: translateY(-50%);
        background: #0b0b0b20;
        width: 1px;
        height: 20px;
        position: absolute;
      }
    }
    textarea {
      flex-shrink: 1;
      flex-grow: 1;
      border: none;
      height: _rem(20px);
      font-weight: 400;
      font-size: _rem(13px);
      line-height: _rem(16px);
      color: #0b0b0b80;
      padding-right: _rem(20px);
      &::placeholder {
        color: #0b0b0b80;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &-name {
    font-family: SFProDisplay_700;
    font-size: _rem(17px);
    line-height: _rem(20px);
    color: #000000;
    margin-bottom: _rem(8px);
  }
  &-id {
    font-weight: 400;
    font-size: _rem(11px);
    line-height: _rem(13px);
    color: #000000;
    opacity: 0.5;
  }
  &-list {
    & > * {
      &:not(:last-child) {
        margin-bottom: _rem(20px);
      }
    }
  }
  &-item {
    display: flex;
    &-avatar {
      margin-right: _rem(15px);
    }
    &-date {
      font-family: SFProDisplay_400;
      font-size: _rem(11px);
      line-height: _rem(13px);
      color: #0b0b0b;
      opacity: 0.4;
    }
  }
  &-text {
    font-family: SFProDisplay_400;
    font-size: _rem(13px);
    line-height: _rem(18px);
    color: #0b0b0b;
    margin-bottom: _rem(15px);
  }
  &-show-all {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.85) 48.57%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 200px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: _rem(33px) _rem(25px);
    button {
      transform: matrix(-1, 0, 0, 1, 0, 0) rotate(180deg);
      background: $white;
    }
  }
}

.plashka {
  background: $white;
  border-radius: 3px;
  padding: _rem(20px) _rem(5px) _rem(5px);

  h4 {
    font-family: SFProDisplay_400;
    font-size: _rem(12px);
    line-height: _rem(14px);
    opacity: 0.4;
    color: $black;
    margin-bottom: _rem(5px);
    margin-left: 10px;
  }
  strong {
    display: flex;
    font-family: SFProDisplay_800;
    font-size: _rem(22px);
    line-height: _rem(26px);
    color: $black;
    margin-bottom: _rem(20px);
    margin-left: 10px;
  }
}

.info-product-share {
  display: block;
  padding: 0 0.3125rem 0.3125rem;

  & > * {
    width: 100%;
    margin-top: 10px;
    font-weight: 600;
    padding: 0.6rem 0;
  }

  & > .btn-white:hover {
    background: rgba(238,125,62,255);
    color: #ffffff;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .favorite-icon,
    .share-icon{
      margin-left: 5px;
    }

    .favorite-icon > svg {
      width: 18px;
      height: 18px;
    }

    .share-icon > svg {
      width: 18px;
      height: 18px;
    }

    &:hover .share-icon > svg > path {
      stroke: #ffffff;
    }

    .favorite-icon > svg > path {
      stroke: #ffffff;
    }

    .favorite-icon > svg > path.active {
      stroke: rgba(238,125,62,255);
      fill: rgba(238,125,62,255);
    }

    &:hover .favorite-icon > svg > path.active {
      stroke: #ffffff;
      fill: #ffffff;
    }
  }
}