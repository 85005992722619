@import "../../styles/modules.scss";

.cabinet {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @include tablet {
    flex-direction: column;
    align-items: center;
  }
  &-s {
    padding: _rem(46px) 0 _rem(293px);
  }

  &-profile-logo img {
    border-radius: 50%;
  }
}

.all-products-header {
  display: block;
  margin-bottom: 1rem !important;
  font-family: SFProDisplay_400;
  font-size: 0.9rem;
  color: black;
  text-transform: capitalize;
}

.cabinet-s {
  .settings-s,
  .advert-s,
  .photos-work-s {
    //margin-right: _rem(129px);
    //@include laptop {
    //  margin-right: 0.625rem;
    //}
  }
  .photos-work-s {
    width: _rem(459px);
  }
  .container-sm {
    @include phones {
      max-width: 100%;
    }
  }
}

.advert {
  .alert-advert {
    margin-bottom: _rem(22px);
  }
  .alert-advert-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-list {
    min-width: 460px;
    @media screen and (max-width: 530px) {
      min-width: 100% !important;
      width: 100%;
    }
    & > * {
      &:not(:last-child) {
        margin-bottom: _rem(30px);
      }
    }
  }
  &-item {
    width: 100%;
    &__top {
      height: _rem(217px);
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__bottom {
      padding: _rem(15px) _rem(20px) _rem(28px) _rem(15px);
      background-color: $white;
    }
    &-info {
      &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: _rem(10px);
        p,
        span {
          font-family: SFProDisplay_400;
          line-height: _rem(17px);
          color: #111111;
        }
      }
      &__bottom {
        display: flex;
        justify-content: space-between;
        margin-bottom: _rem(20px);
        p,
        span {
          font-family: SFProDisplay_400;
          font-size: _rem(10px);
          line-height: _rem(12px);
          color: #783c11;
        }
      }
    }
    &-date {
      p {
        font-family: SFProDisplay_400;
        font-size: _rem(10px);
        line-height: _rem(12px);
        color: #111111;
        opacity: 0.5;
        margin-top: _rem(10px);
      }
    }
    &-status {
      padding: _rem(4px) _rem(12px) _rem(4px) _rem(5px);
      position: absolute;
      left: _rem(15px);
      top: _rem(20px);
      border-radius: 50px;
      font-weight: 600;
      font-size: _rem(11px);
      line-height: _rem(13px);
      color: #ffffff;
      display: flex;
      align-items: center;
      & > * {
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
      &-icon {
        display: flex;
        @include size(_rem(16px));
        border-radius: 50%;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        max-width: _rem(8px);
        max-height: _rem(8px);
        position: relative;
        right: -0.5px;
      }
      &.wait-status {
        background: $warning;
        svg {
          fill: $orange;
        }
      }
      &.error-status {
        background: $red;
        svg {
          fill: $red;
          right: 0;
        }
      }
      &.done-status {
        background: $green;
        svg {
          right: 0;
        }
      }
    }
  }
  &-settings {
    position: relative;
    &-body {
      position: absolute;
      top: _rem(55px);
      right: _rem(-14px);
      padding: _rem(25px) _rem(40px) _rem(25px) _rem(25px);
      background: $white;
      border-radius: 3px;
      box-shadow: 0px 0px 10px 3px rgb(0, 0, 0, 0.05);
      visibility: visible;
      opacity: 0;
      z-index: 4;
      visibility: hidden;
      transition: all ease 0.2s;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      ul {
        & > * {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
      span {
        font-family: SFProDisplay_600;
        font-size: _rem(14px);
        // width: max-content;
        white-space: nowrap;
        color: $black;
        transition: all ease 0.2s;
        &:hover {
          color: $orange;
        }
      }
    }
  }
}
.statistic-list {
  display: flex;
  & > * {
    &:not(:last-child) {
      margin-right: _rem(30px);
    }
  }
  li {
    display: flex;
    span {
      font-family: SFProDisplay_400;
      font-size: _rem(12px);
      line-height: _rem(14px);
      color: #111111;
      opacity: 0.5;
      margin-right: 9px;
      display: block;
    }
    strong {
      font-family: SFProDisplay_600;
      font-size: _rem(12px);
      line-height: _rem(14px);
      color: #111111;
    }
  }
}
.alert-advert {
  background: #ffffff;
  border-radius: 4px;
  padding: _rem(25px);
  h5 {
    font-family: SFProDisplay_600;
    font-size: _rem(15px);
    line-height: _rem(18px);
    color: #0b0b0b;
    margin-bottom: _rem(5px);
  }
  p {
    font-family: SFProDisplay_400;
    line-height: _rem(17px);
    color: #0b0b0b;
    opacity: 0.6;
    margin-bottom: _rem(20px);
  }
  button {
    margin-right: _rem(40px);
  }
  a {
    font-weight: 800;
    font-size: _rem(13px);
    line-height: _rem(16px);
    text-align: center;
    color: $orange;
  }
}

.btn-settings {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  width: _rem(40px);
  height: _rem(40px);
  border-radius: 50px;
  position: absolute;
  right: _rem(10px);
  top: _rem(10px);
}

.settings {
  &-profile {
    display: flex;
    & > * {
      &:not(:last-child) {
        margin-right: _rem(16px);
      }
    }
    &-logo {
      width: _rem(96px);
      height: _rem(96px);
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-info {
      padding-top: _rem(14px);
      h3 {
        font-family: SFProDisplay_700;
        font-size: _rem(17px);
        line-height: _rem(20px);
        color: #000000;
        margin-bottom: _rem(12px);
      }
      button {
        padding: _rem(5px) _rem(10px);
        font-size: _rem(11px);
        line-height: _rem(13px);
      }
    }
  }
  &-form {
    margin-top: _rem(22px);
    display: flex;
    flex-wrap: wrap;
    & > *:not(:nth-child(2n)) {
      margin-right: 2.1%;
    }
    .form-input {
      flex-basis: 48.95%;
    }
    .change-password {
      margin-right: 0;
    }
  }
  .btn-orange {
    width: auto;
    padding: _rem(13px) _rem(32px);
  }
}

.change-password {
  background: #ffffff;
  border-radius: 3px;
  padding: _rem(30px) _rem(60px) _rem(65px) _rem(30px);
  margin-bottom: _rem(48px);
  h3 {
    font-family: SFProDisplay_800;
    font-size: _rem(22px);
    line-height: _rem(26px);
    color: #0b0b0b;
    margin-bottom: 7px;
  }
  &-subtitle {
    font-family: SFProDisplay_400;
    font-size: _rem(11px);
    line-height: _rem(16px);
    color: #0b0b0b;
    margin-bottom: _rem(27px);
  }
  label {
    font-family: SFProDisplay_400;
    font-size: _rem(13px);
    line-height: _rem(18px);
    color: #0f1826;
    display: flex;
    margin-bottom: _rem(10px);
  }
  input {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: _rem(14px);
    width: 100%;
  }
  &-par {
    font-family: SFProDisplay_400;
    font-size: _rem(11px);
    line-height: _rem(13px);
    color: #0f1826;
    opacity: 0.4;
    margin-top: _rem(10px);
  }
}

.reffer {

  .form-input {
    margin-bottom: 0.25rem;
  }

  &__getlink {
    color: #1B74FF;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
  }

  &__input {
    cursor: pointer;
  }

  &__points {
    margin: 20px 0;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.125rem;
    color: #000000;
    font-weight: 600;
  }

  &__points > svg {
    margin-right: 5px;
  }

}