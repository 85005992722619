@font-face {
	font-family: SFProDisplay_900;
	font-display: swap;
	src: url('../fonts/fontBlack.woff2') format('woff2'),  url('../fonts/fontBlack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
font-family: SFProDisplay_700;
font-display: swap;
src: url('../fonts/fontBold.woff2') format('woff2'),  url('../fonts/fontBold.woff') format('woff');
font-weight: 700;
font-style: normal;
}

          
@font-face {
font-family: SFProDisplay_800;
font-display: swap;
src: url('../fonts/fontHeavy.woff2') format('woff2'),  url('../fonts/fontHeavy.woff') format('woff');
font-weight: 800;
font-style: normal;
}
						
@font-face {
	font-family: SFProDisplay_500;
	font-display: swap;
	src: url('../fonts/fontMedium.woff2') format('woff2'),  url('../fonts/fontMedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

						
@font-face {
	font-family: SFProDisplay_400;
	font-display: swap;
	src: url('../fonts/fontRegular.woff2') format('woff2'),  url('../fonts/fontRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
						
@font-face {
	font-family: SFProDisplay_600;
	font-display: swap;
	src: url('../fonts/fontSemibold.woff2') format('woff2'),  url('../fonts/fontSemibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}